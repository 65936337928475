<script setup lang="ts">
import Article, { ArticleAttrs } from '~~/utils/models/Article';
import IconArrowRight2 from '~~/assets/svg/icons/arrow-right-2.svg';

const props = defineProps<{ article: ArticleAttrs }>();
const article$ = computed(() => new Article(props.article));
</script>

<template>
  <div
    class="h-entry flex gap-16 lg:gap-24 relative transition-filter duration-300 hover:grayscale-50"
  >
    <picture class="u-photo shrink-0">
      <source
        media="(max-width: 1023px)"
        :srcset="`
          ${article$.getThumbnailURL(120, 120)},
          ${article$.getThumbnailURL(240, 240)} 2x
        `"
      />
      <source
        :srcset="`
          ${article$.getThumbnailURL(138, 138)},
          ${article$.getThumbnailURL(267, 276)} 2x
        `"
      />
      <img
        width="120"
        height="120"
        loading="lazy"
        :alt="article$.data.title"
        :src="article$.getThumbnailURL(120, 120)"
      />
    </picture>

    <div>
      <div class="flex items-center gap-8 mb-8 lg:mb-16">
        <span class="lg:hidden -mt-3">•</span>
        <span class="hidden lg:inline-block bg-white w-[8px] h-[2px]" />
        <time
          class="dt-published text-12 leading-20"
          :datetime="article$.data.publishedAt"
        >
          {{ article$.publishedAt.format('D MMMM YYYY') }}
        </time>
      </div>

      <h3
        class="p-name text-16 lg:text-18 leading-24 lg:leading-26 font-medium lg:mb-16"
      >
        {{ article$.data.title }}
      </h3>

      <!-- <div
        class="h-card flex gap-8 items-center mb-16"
        v-if="article$.data.earnedCoin === null"
      >
        <img
          alt="SHVR Coins"
          width="20"
          height="20"
          loading="lazy"
          :src="
            $img('/assets/img/articles/checkmark.svg', {
              width: 20,
              height: 20,
            })
          "
        />
        <p class="text-[#32D74B]/100 font-bold text-14 mb-0">Selesai</p>
      </div>

      <div
        class="h-card flex gap-8 items-center mb-16"
        v-else-if="article$.data.earnedCoin > 0"
      >
        <img
          alt="SHVR Coins"
          width="20"
          height="20"
          loading="lazy"
          :src="
            $img('/assets/img/articles/shvr-coin-silver.png', {
              width: 20,
              height: 20,
            })
          "
        />
        <p class="text-[#FFA200]/100 font-bold text-14 mb-0">
          +{{ new Intl.NumberFormat().format(article$.data.earnedCoin) }} SHVR
          Coins
        </p>
      </div> -->

      <span
        class="text-14 leading-19 no-underline flex gap-4 items-center opacity-72"
      >
        Read More
        <IconArrowRight2 width="48" height="24" />
      </span>
    </div>

    <RouterLink
      class="u-url stretched-link"
      :aria-label="article$.data.title"
      :to="article$.routePath"
    />
  </div>
</template>
