<script setup lang="ts">
import Article, { ArticleAttrs } from '~~/utils/models/Article';
import IconArrowRight2 from '~~/assets/svg/icons/arrow-right-2.svg';

const props = defineProps<{ article: ArticleAttrs }>();
const article$ = computed(() => new Article(props.article));
</script>

<template>
  <div
    class="h-entry lg:flex gap-32 relative transition-filter duration-300 hover:grayscale-50"
  >
    <picture class="u-photo shrink-0 block mb-24 lg:mb-0">
      <source
        media="(max-width: 1023px)"
        :srcset="`
          ${article$.getThumbnailURL(343, 248)},
          ${article$.getThumbnailURL(686, 496)} 2x
        `"
      />
      <source
        :srcset="`
          ${article$.getThumbnailURL(360, 464)},
          ${article$.getThumbnailURL(720, 928)} 2x
        `"
      />
      <img
        width="343"
        height="248"
        loading="lazy"
        :alt="article$.data.title"
        :src="article$.getThumbnailURL(343, 248)"
      />
    </picture>
    <div>
      <ArticleAuthorPublishDate class="lg:hidden mb-16" :article$="article$" />

      <div class="p-author h-card hidden lg:flex gap-8 items-center mb-24">
        <picture class="u-photo">
          <source
            type="image/webp"
            :srcset="`
              ${$img('/assets/img/articles/shvr.png', {
                width: 40,
                format: 'webp',
              })},
              ${$img('/assets/img/articles/shvr.png', {
                width: 80,
                format: 'webp',
              })} 2x
            `"
          />
          <img
            alt="SHVR"
            width="40"
            height="40"
            loading="lazy"
            :src="$img('/assets/img/articles/shvr.png', { width: 40 })"
          />
        </picture>
        <div>
          <div class="p-name leading-19 mb-4">SHVR</div>
          <div class="text-12 leading-14 text-white/60">Author</div>
        </div>
      </div>

      <div class="hidden lg:flex items-center gap-8 mb-24">
        <span class="d-inline-block bg-white/88 w-[16px] h-[2px]" />
        <time
          class="dt-published text-12 leading-20"
          :datetime="article$.data.publishedAt"
        >
          {{ article$.publishedAt.format('D MMMM YYYY') }}
        </time>
      </div>

      <h3
        class="p-name text-24 lg:text-32 leading-40 font-medium mb-16 lg:mb-24"
      >
        {{ article$.data.title }}
      </h3>

      <p
        class="p-summary text-14 lg:text-16 leading-22 lg:leading-28 line-clamp-5 mb-16 lg:mb-24"
        v-if="article$.data.description"
      >
        {{ article$.data.description }}
      </p>

      <!-- <div
        class="h-card flex gap-8 items-center mb-16"
        v-if="article$.data.earnedCoin === null"
      >
        <img
          alt="SHVR Coins"
          width="20"
          height="20"
          loading="lazy"
          :src="
            $img('/assets/img/articles/checkmark.svg', {
              width: 20,
              height: 20,
            })
          "
        />
        <p class="text-[#32D74B]/100 font-bold text-14 mb-0">Selesai</p>
      </div>

      <div
        class="h-card flex gap-8 items-center mb-16"
        v-else-if="article$.data.earnedCoin > 0"
      >
        <img
          alt="SHVR Coins"
          width="20"
          height="20"
          loading="lazy"
          :src="
            $img('/assets/img/articles/shvr-coin-silver.png', {
              width: 20,
              height: 20,
            })
          "
        />
        <p class="text-[#FFA200]/100 font-bold text-14 mb-0">
          +{{ new Intl.NumberFormat().format(article$.data.earnedCoin) }} SHVR
          Coins
        </p>
      </div> -->

      <span
        class="lg:text-20 lg:leading-27 no-underline flex items-center gap-4 opacity-72"
      >
        Read More
        <IconArrowRight2 width="48" height="24" />
      </span>
    </div>

    <RouterLink
      class="u-url stretched-link text-20 leading-27 no-underline flex items-center"
      :aria-label="article$.data.title"
      :to="article$.routePath"
    />
  </div>
</template>
